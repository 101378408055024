<template>
  <main>
    <div class="bg-white sticky top-0 z-10">
      <sqr-page-header title="Recherche" class="p-4"> </sqr-page-header>
      <nav-tabs :tabs="tabs" />
    </div>
    <router-view></router-view>
  </main>
</template>

<style lang="postcss">
.ais-SearchBox-form {
  @apply flex items-center;
}
.ais-SearchBox-input {
  @apply mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm;
  @apply transition duration-150 ease-in-out;
}
.ais-SearchBox-submit {
  @apply h-8 w-8 p-2;
}
.ais-SearchBox-reset {
  @apply h-8 w-8 p-2;
}
.ais-Pagination-list {
  @apply relative z-0 inline-flex shadow-sm;
}
.ais-Pagination-item {
  @apply -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 transition ease-in-out duration-150;
}
.ais-Pagination-item:focus {
  @apply z-10 outline-none border-blue-300 shadow-outline-blue;
}
/* hover:text-gray-500  active:bg-gray-100 active:text-gray-700  */
.ais-Pagination-item--firstPage {
  @apply px-4 py-2 rounded-l-md;
  /* hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 */
}
.ais-Pagination-item--lastPage {
  @apply px-4 py-2 rounded-r-md;
  /* hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 */
}
.ais-Pagination-item--disabled {
}
.ais-ToggleRefinement-label {
  @apply text-sm;
}
.ais-RefinementList-labelText {
  @apply text-sm;
}
.ais-RefinementList-count {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800;
}
.ais-RefinementList-showMore {
  @apply text-sm;
}
.ais-RefinementList-showMore:hover {
  @apply text-blue-500;
}
</style>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import NavTabs from '@/components/NavTabs';

export default {
  name: 'Search',
  components: { NavTabs },
  props: {
    bid: String
  },
  computed: {
    tabs() {
      const bid = this.bid;
      return [
        {
          id: 'relations',
          label: 'Relations',
          to: { name: 'search-relations', params: { bid } }
        },
        {
          id: 'policies',
          label: 'Polices',
          to: { name: 'search-policies', params: { bid } }
        }
      ];
    }
  }
};
</script>
